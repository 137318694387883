const evaluationsKpiPAT = [
  {
    id: 1,
    klasifikasi: "Berikan penilaian terhadap teman sekelompok Kamu dalam aspek Kontribusi Akademik (sejauh mana ia aktif mencari informasi/bacaan ilmiah/ membaca/ikut aktif berdiskusi/aktif memberikan pandangan/analisis)."
  },
  {
    id: 2,
    klasifikasi: "Berikan penilaian terhadap teman sekelompok Kamu dalam aspek Kontribusi Non-Akademik (sejauh mana ia aktif membantu penyelesaian tugas dalam bentuk ketrampilan teknis misalnya mengetik tugas, membuat presentasi dengan aplikasi power point, membuatkan ilustrasi."
  },
  {
    id: 3,
    klasifikasi: "Berkan penilaian terhadap teman sekelompok Kamu dalam aspek Kontribusi berbentuk soft skill (sejauh mana ia aktif mendorong/memotivasi kelompok untuk bekerja sama dengan baik, dan berkomunikasi secara positif)."
  },
  {
    id: 4,
    klasifikasi: "Seberapa sering teman sekelompok Kamu hadir selama kegiatan berlangsung?"
  },
  {
    id: 5,
    klasifikasi: "Apakah teman sekelompok Kamu berhak mendapatkan penilaian kegiatan, konversi SKS (bagi yang mendaftar program konversi), sertifikat, eksposur di media sosial, dan surat rekomendasi kerja dari Bakrie Center Foundation?"
  },
  {
    id: 6,
    klasifikasi: "Apakah teman sekelompok Kamu berhak mendapatkan dana dukungan mahasiswa?"
  }
];

const evaluationsKpiBCF = [
  {
    id: 1,
    klasifikasi: "Misi"
  },
  {
    id: 2,
    klasifikasi: "Kehadiran"
  },
  {
    id: 3,
    klasifikasi: "Kerjasama & Keaktifan"
  },
  {
    id: 4,
    klasifikasi: "Paper Konferensi"
  },
  {
    id: 5,
    klasifikasi: "Kuis"
  }
];

const evaluationsKpiSoftskill = [
  {
    id: 1,
    klasifikasi: "Berkomunikasi yang Baik"
  },
  {
    id: 2,
    klasifikasi: "Bekerjasama dalam Tim"
  },
  {
    id: 3,
    klasifikasi: "Toleransi terhadap Perbedaan"
  },
  {
    id: 4,
    klasifikasi: "Peduli Terhadap Kesusahan Orang Lain"
  },
  {
    id: 5,
    klasifikasi: "Peka Terhadap Situasi Sekitar"
  }
]

const evaluationsKpiHardskill = [
  {
    id: 1,
    klasifikasi: "Berkomunikasi yang Baik"
  },
  {
    id: 2,
    klasifikasi: "Bekerjasama dalam Tim"
  },
  {
    id: 3,
    klasifikasi: "Toleransi terhadap Perbedaan"
  },
  {
    id: 4,
    klasifikasi: "Peduli Terhadap Kesusahan Orang Lain"
  },
  {
    id: 5,
    klasifikasi: "Peka Terhadap Situasi Sekitar"
  }
];

const listFriendsPAT = [
  {
    fullname: "",
    id: 1,
    isEvaluated: true
  },
  {
    fullname: "",
    id: 2,
    isEvaluated: true
  },
  {
    fullname: "",
    id: 3,
    isEvaluated: true
  },
  {
    fullname: "",
    id: 4,
    isEvaluated: true
  },
  {
    fullname: "",
    id: 5,
    isEvaluated: true
  }
]

const finalEvaluation = {
  id: 0,
  mentors: "",
  mentorsId: 0,
  mentess: "",
  usersId: 0,
  jurusan: "",
  universitas: "",
  divisi: "",
  id_divisi_lembaga: 0,
  catatan: "",
  url_sertifikat: "",
  total_nilai: 0,
  average_nilai: 0,
  by_bcf: [
    {
      nilai: 0,
      catatan: "",
      klasifikasi: "Misi"
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: "Kehadiran"
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: "Kerjasama & Keaktifan"
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: "Paper Konferensi"
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: "Kuis"
    }
  ],
  softskills_by_mentors: [
    {
      nilai: 0,
      catatan: "",
      klasifikasi: "Berkomunikasi yang Baik"
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: "Bekerjasama dalam Tim"
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: "Toleransi terhadap Perbedaan"
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: "Peduli Terhadap Kesusahan Orang Lain"
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: "Peka Terhadap Situasi Sekitar"
    }
  ],
  hardskills_by_mentors: [
    {
      nilai: 0,
      catatan: "",
      klasifikasi: ""
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: ""
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: ""
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: ""
    },
    {
      nilai: 0,
      catatan: "",
      klasifikasi: ""
    }
  ]
}

export {
  evaluationsKpiPAT,
  evaluationsKpiBCF,
  evaluationsKpiSoftskill,
  evaluationsKpiHardskill,
  listFriendsPAT,
  finalEvaluation,
}