import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navigation = ({ authUser, signOut }) => {
  const location = useLocation();
  const pathname = location.pathname.split('/');
  const slug = pathname[1];

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light shadow-sm">
        <div className="container p-2">
          {/* <Link className="navbar-brand" to='/'>NGEDATE.id</Link> */}
          <Link className="navbar-brand" to='/'>
            <img src="/logo192.png" style={{ height: 50, objectFit: 'scale-down' }} className='img-fluid' alt='ngedate.id' />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className={`nav-link ${slug === 'publication' && 'active'}`} aria-current="page" to="/publication">Publikasi</Link>
              </li>
              {/* {authUser &&
              <li className="nav-item">
                <Link className={`nav-link ${slug === 'update' && 'active'}`} to="/update">perbarui data <span class="badge text-bg-danger">CLP 8</span></Link>
              </li>
            } */}
            </ul>

            {!authUser ?
              <div className="text-end">
                <Link to='/app/login' type="button" className="btn btn-primary">Sign in</Link>
              </div>
              :
              <>
                {authUser?.role === 'Admin' &&
                  <div className="text-end">
                    <p>LoggedIn As:{' '}
                      <Link to='/admin'>
                        <b>Admin</b>
                      </Link>
                    </p>
                  </div>
                }
                {authUser?.role === 'Mentors' &&
                  <div className="text-end">
                    <p>LoggedIn As:{' '}
                      <Link to='/mentors'>
                        <b>Mentors</b>
                      </Link>
                    </p>
                  </div>
                }
                {authUser?.role === 'Users' &&
                  <>
                    <div className="flex-shrink-0 dropdown">
                      <a href="#/" className="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <span>{authUser.fullname}</span>
                        {/* <img className='rounded-circle border border-2' style={{ height: 50, width: 50, objectFit: 'cover', marginLeft: 10 }} src={authUser.image} alt={authUser.name} /> */}
                        {/* {authUser.status > 0 &&
                    <span className="position-absolute top-0 translate-middle">
                      <i className="bi bi-patch-check-fill" style={{ color: 'red' }}></i>
                    </span>
                  } */}
                      </a>
                      <ul className="dropdown-menu text-small shadow">
                        {/* <li><Link className="dropdown-item" to={`/${role}`}>App</Link></li> */}
                        <li><Link className="dropdown-item" to={`/app/publication`}>Publikasi</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link className="dropdown-item" to={`/app/evaluation/final`}>Penilaian</Link></li>
                        <li><Link className="dropdown-item" to={`/app/evaluation`}>
                          Penilaian (PAT) {' '}
                          <span class="badge text-bg-success">New</span></Link>
                        </li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link className="dropdown-item" to={`/app/profile`}>Profil</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><a onClick={signOut} className="dropdown-item" href="#/">Sign out</a></li>
                      </ul>
                    </div>
                  </>
                }
              </>
            }

          </div>
        </div>
      </nav>

      {/* <div class="alert alert-warning text-center" role="alert">
        <h4 class="alert-heading">Server maintenance!</h4>
        Haii, teman-teman. Server akan berjalan normal pada hari Senin, 08/Juli/2024.
      </div> */}
    </>
  )
}

export default Navigation;
