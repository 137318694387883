import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { listFriendsPAT } from '../../../utils/data';
import { useNavigate } from 'react-router-dom';

const EvaluationList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [evaluates, setEvaluates] = useState(listFriendsPAT);

  useEffect(() => {
    const getPATFriends = async () => {
      setLoading(true);
      const data = await api.appGetFriendsDivisiPAT();
      setEvaluates(data);
      setLoading(false);
    }

    getPATFriends();
  }, []);

  return (
    <div className='card'>
      <div className="card-body table-responsive">
        <h1 className='h5'>
          Teman Divisi{' '}
          {/* <span class="badge text-bg-warning">On Testing</span> */}
        </h1>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama</th>
              <th scope="col">Status</th>
              <th scope="col">Aksi</th>
            </tr>
          </thead>
          <tbody>
            {loading ?
              <>
                {evaluates?.map(() => (
                  <tr className='placeholder-glow'>
                    <th><span className='placeholder col-12'></span></th>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12 bg-primary'></span></td>
                  </tr>
                ))}
              </>
              :
              <>
                {evaluates?.map((evaluate, key) => (
                  <tr key={key} style={{ cursor: 'default' }}>
                    <th scope="row">{key + 1}</th>
                    <td>{evaluate.fullname}</td>
                    <td>{evaluate.isEvaluated ?
                      <span class="badge rounded-pill text-bg-success">Sudah dinilai</span> :
                      <span class="badge rounded-pill text-bg-warning">Belum Menilai</span>}
                    </td>
                    <td><button disabled={evaluate.isEvaluated} onClick={() => navigate(`/app/evaluation/${evaluate.id}/evaluate`)} className='btn btn-primary badge rounded-pill'>Nilai</button></td>
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
      </div>
    </div >
  )
}

export default EvaluationList