import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { Link } from 'react-router-dom';

const Index = () => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const onHandlerGetProfileByNim = async () => {
      const data = await api.getOwnFullProfile();
      setUserData(data)
    }

    onHandlerGetProfileByNim();
  }, []);

  return (
    <div className='row g-4'>
      <div className="col-12 col-md-8">
        <div className="row g-3">

          <div className='shadow-sm'>
            <div className='card-body m-3'>
              <h4 className="mb-3">Data Diri</h4>

              <div className="row g-3">
                <div className="col-12">
                  <label htmlFor="address" className="form-label">Tanggal Lahir</label>
                  <input disabled value={userData?.tgl_lahir} type="date" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="username" className="form-label">Nomor KTP</label>
                  <input disabled value={userData?.nomor_ktp} type="text" className="form-control" id="username" required="" />
                </div>

                <div className="col-6">
                  <label htmlFor="country" className="form-label">Provinsi Domisili KTP</label>
                  <input disabled value={userData?.provinsi_domisili_ktp} type="text" className="form-control" id="username" required="" />
                </div>

                <div className="col-6">
                  <label htmlFor="country" className="form-label">Kota Domisili KTP</label>
                  <input disabled value={userData?.kota_domisili_ktp} type="text" className="form-control" id="username" required="" />
                </div>

                <div className="col-6">
                  <label htmlFor="country" className="form-label">Provinsi Domisili Sekarang</label>
                  <input disabled value={userData?.provinsi_domisili_now} type="text" className="form-control" id="username" required="" />
                </div>

                <div className="col-6">
                  <label htmlFor="country" className="form-label">Kota Domisili Sekarang</label>
                  <input disabled value={userData?.kota_domisili_now} type="text" className="form-control" id="username" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Alamat Lengkap Mahasiswa (Berdasarkan KTP)</label>
                  <input disabled value={userData?.alamat_lengkap_ktp} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Alamat Lengkap Domisili Mahasiswa (Tempat Tinggal Saat Ini)</label>
                  <input disabled value={userData?.alamat_lengkap_now} type="text" className="form-control" id="address" required="" />
                </div>
              </div>

            </div>
          </div>

          <div className='shadow-sm'>
            <div className='card-body m-3'>
              <h4 className="mb-3">Rekening</h4>

              <div className="row g-3">
                <div className="col-12">
                  <label htmlFor="address" className="form-label">Nomor Rekening Mahasiswa</label>
                  <input disabled value={userData?.nomor_rekening} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Nama Lengkap Pemilik Rekening (Nama Sesuai Buku Rekening)</label>
                  <input disabled value={userData?.nama_rekening} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Nama Bank</label>
                  <input disabled value={userData?.nama_bank} type="text" className="form-control" id="address" required="" />
                </div>
              </div>

            </div>
          </div>

          <div className='shadow-sm'>
            <div className='card-body m-3'>
              <h4 className="mb-3">Kontak Darurat</h4>

              <div className="row g-3">
                <div className="col-6">
                  <label htmlFor="address" className="form-label">Nomor HP Darurat</label>
                  <input disabled value={userData?.nomor_hp_darurat} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-6">
                  <label htmlFor="address" className="form-label">Hubungan Nomor HP Darurat</label>
                  <input disabled value={userData?.hubungan_nomor_hp_darurat} type="text" className="form-control" id="address" required="" />
                </div>
              </div>

            </div>
          </div>

          <div className='shadow-sm'>
            <div className='card-body m-3'>
              <h4 className="mb-3">Universitas</h4>

              <div className="row g-3">

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Asal Universitas</label>
                  <input disabled value={userData?.asal_universitas} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Provinsi Domisili Universitas</label>
                  <input disabled value={userData?.provinsi_domisili_universitas} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Kota Domisili Universitas</label>
                  <input disabled value={userData?.kota_domisili_universitas} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Program Studi</label>
                  <input disabled value={userData?.program_studi} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Jenjang Pendidikan</label>
                  <input disabled value={userData?.jenjang_pendidikan} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Tahun Angkatan Kuliah</label>
                  <input disabled value={userData?.tahun_angkatan_kuliah} type="text" className="form-control" id="address" required="" />
                </div>

              </div>

            </div>
          </div>

          <div className='shadow-sm'>
            <div className='card-body m-3'>
              <h4 className="mb-3">Program</h4>

              <div className="row g-3">
                <div className="col-6">
                  <label htmlFor="address" className="form-label">Program</label>
                  <input disabled value={userData?.program} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-6">
                  <label htmlFor="address" className="form-label">Cluster</label>
                  <input disabled value={userData?.cluster} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Penempatan</label>
                  <input disabled value={userData?.penempatan} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Lembaga Mitra Penempatan Magang</label>
                  <input disabled value={userData?.lembaga_penempatan} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Lembaga Divisi/Bidang</label>
                  <input disabled value={userData?.lembaga_divisi} type="text" className="form-control" id="address" required="" />
                </div>
              </div>

            </div>
          </div>

          <div className='shadow-sm'>
            <div className='card-body m-3'>
              <h4 className="mb-3">Keberangkatan</h4>

              <div className="row g-3">

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Pengajuan Mobilisasi Keberangkatan</label>
                  <input disabled value={userData?.mob_keb_pengajuan_keberangkatan} type="text" className="form-control" id="address" required="" />
                </div>

                {userData?.mob_keb_pengajuan_keberangkatan === 'Mengajukan Keberangkatan' &&
                  <>
                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Provinsi keberangkatan</label>
                      <input disabled value={userData?.mob_keb_provinsi_keberangkatan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Moda Keberangkatan</label>
                      <input disabled value={userData?.mob_keb_moda_keberangkatan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Domisili Provinsi Asal Keberangkatan</label>
                      <input disabled value={userData?.mob_keb_provinsi_asal_keberangkatan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Bandara / Stasiun Keberangkatan</label>
                      <input disabled value={userData?.mob_keb_bandara_stasiun_keberangkatan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Provinsi Bandara / Stasiun Keberangkatan</label>
                      <input disabled value={userData?.mob_keb_prov_bandara_stasiun_keberangkatan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Bandara / Stasiun Tujuan</label>
                      <input disabled value={userData?.mob_keb_bandara_stasiun_tujuan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Provinsi Bandara / Stasiun Tujuan</label>
                      <input disabled value={userData?.mob_keb_prov_bandara_stasiun_tujuan} type="text" className="form-control" id="address" required="" />
                    </div>
                  </>
                }

              </div>

            </div>
          </div>

          <div className='shadow-sm'>
            <div className='card-body m-3'>
              <h4 className="mb-3">Kepulangan</h4>

              <div className="row g-3">

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Pengajuan Mobilisasi Kepulangan</label>
                  <input disabled value={userData?.mob_kep_pengajuan_kepulangan} type="text" className="form-control" id="address" required="" />
                </div>

                {userData?.mob_kep_pengajuan_kepulangan === 'Mengajukan Kepulangan' &&
                  <>
                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Provinsi Kepulangan</label>
                      <input disabled value={userData?.mob_kep_provinsi_kepulangan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Moda Kepulangan</label>
                      <input disabled value={userData?.mob_kep_moda_kepulangan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Domisili Provinsi Asal Keberangkatan (Menuju Lokasi Pulang)</label>
                      <input disabled value={userData?.mob_kep_provinsi_asal_keberangkatan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Bandara / Stasiun Kepulangan</label>
                      <input disabled value={userData?.mob_kep_bandara_stasiun_kepulangan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Provinsi Bandara / Stasiun Kepulangan</label>
                      <input disabled value={userData?.mob_kep_prov_bandara_stasiun_kepulangan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Bandara / Stasiun Tujuan</label>
                      <input disabled value={userData?.mob_kep_bandara_stasiun_tujuan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Provinsi Bandara / Stasiun Tujuan</label>
                      <input disabled value={userData?.mob_kep_prov_bandara_stasiun_tujuan} type="text" className="form-control" id="address" required="" />
                    </div>
                  </>
                }

              </div>

            </div>
          </div>

          <div className="shadow-sm">
            <div className="card-body m-3">
              <div className="col-12">
                <label htmlFor="country" className="form-label">Surat Keterangan (Google Drive Link)</label>
                <input disabled value={userData?.mob_url_surat_keterangan} type="text" className="form-control" id="address" required="" />
              </div>
            </div>
          </div>

          <div className='shadow-sm'>
            <div className='card-body m-3'>
              <h4 className="mb-3">Minat Dan Bakat</h4>

              <div className="row g-3">

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Kemampuan Apa yang Kamu Miliki?</label>
                  <textarea disabled value={userData?.kemampuan} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Alat Musik Apa yang Dapat Kamu Mainkan?</label>
                  <textarea disabled value={userData?.alat_musik} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Dekripsikan Pengalaman terkait Bakat/Kemampuan yang Kamu Miliki</label>
                  <textarea disabled value={userData?.deskripsi_kemampuan} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Dalam kurun waktu 5 tahun terakhir, apakah kamu pernah mendapatkan penghargaan yang berkaitan dengan Bakat/Kemampuan yang kamu miliki?</label>
                  <textarea disabled value={userData?.penghargaan_5_tahun} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Kegiatan-kegiatan BCF Mana Saja yang Kamu Minati?</label>
                  <textarea disabled value={userData?.kegiatan_bcf} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Apakah kamu memiliki kesibukan lain saat ini? Silakan sebutkan dan jelaskan secara singkat.</label>
                  <textarea disabled value={userData?.kesibukan_lain} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Apakah rencanamu dalam waku 5 tahun ke depan, dalam aspek karir maupun pendidikan?</label>
                  <textarea disabled value={userData?.rencana_5_tahun} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Ceritakan secara singkat mengenai rencanamu ke depan sesuai dengan pilihan yang kamu isi pada pertanyaan sebelumnya.</label>
                  <textarea disabled value={userData?.deskripsi_rencana_5_tahun} type="text" className="form-control" id="address" required="" />
                </div>

              </div>

            </div>
          </div>

          <div className="shadow-sm">
            <div className="card-body p-3">

              <div className="col-12">
                <label htmlFor="address" className="form-label">Foto Diri Mahasiswa untuk ID Card (Google Drive Link)</label>
                <input disabled value={userData?.foto_diri_id_card} type="text" className="form-control" id="address" required="" />
              </div>
            </div>

          </div>

          <div className="shadow-sm">
            <div className="card-body p-3">

              <div className="row g-3">
                <div className="col-12">
                  <label htmlFor="address" className="form-label">Link Akun Instagram Mahasiswa</label>
                  <input disabled value={userData?.username_instagram} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Link Akun TikTok Mahasiswa</label>
                  <input disabled value={userData?.username_tiktok} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">Link Akun LinkedIn Mahasiswa</label>
                  <input disabled value={userData?.username_linkedin} type="text" className="form-control" id="address" required="" />
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div className="col-12 col-md-4 order-md-last order-first">
        <div className="row g-3 sticky-md-top" style={{ zIndex: 99 }}>
          <div className='shadow-sm'>
            <div className='card-body m-3'>
              <h4 className="mb-3">Profil</h4>

              <div className="row g-3">
                <div className="col-12">
                  <label htmlFor="username" className="form-label">NIM</label>
                  <input disabled value={userData?.nim} type="text" className="form-control" id="username" placeholder="Username" required />
                </div>

                <div className="col-12">
                  <label htmlFor="username" className="form-label">Nama</label>
                  <input disabled value={userData?.nama} type="text" className="form-control" id="username" placeholder="Username" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="username" className="form-label">Jenis Kelamin</label>
                  <input disabled value={userData?.jenis_kelamin} type="text" className="form-control" id="username" placeholder="Jenis Kelamin" required="" />
                </div>

                <div className="col-12">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input disabled value={userData?.email} type="email" className="form-control" id="email" placeholder="you@example.com" />
                </div>

                <div className="col-12">
                  <label htmlFor="address" className="form-label">No HP</label>
                  <input disabled value={userData?.nomor_hp} type="text" className="form-control" id="address" required="" />
                </div>

                <div className="d-grid gap-2">
                  <Link to='/app/profile/update/identitas' className='btn btn-primary'>Ubah Profil</Link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Index