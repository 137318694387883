import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import EvaluationDocument from '../../../utils/EvaluationDocument';
import { finalEvaluation } from '../../../utils/data';

const EvaluationFinal = () => {
  const [data, setData] = useState(finalEvaluation);

  useEffect(() => {
    const getEvaluationsById = async () => {
      const data = await api.appGetFinalEvaluations();
      setData(data);
      console.log(data);
    }

    getEvaluationsById();
  }, []);

  return (
    <div className="row g-3">
      <div className='shadow-sm'>
        <div className='card-body m-3'>
          {/* <PDFViewer height={500} width={650}>
            <EvaluationDocument finalEvaluation={data} />
          </PDFViewer> */}
          <div className="d-flex justify-content-between mb-3">
            <h1 className="h5">Penilaian</h1>
            <div className="col-3">
              <div className="d-flex justify-content-end">
                <div className="d-grid gap-2">
                  <PDFDownloadLink className='btn btn-primary' document={<EvaluationDocument finalEvaluation={data} />} fileName={`EVALUATION_${data?.mentess}.pdf`}>
                    {({ loading }) =>
                      loading ?
                        <>
                          <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                          <span role="status">{' '}Loading...</span>
                        </> : 'Download'
                    }
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3">

            <div className="col-12">
              <label htmlFor="Mentess" className="form-label">Mentess</label>
              <div className="input-group has-validation">
                <input disabled value={data?.mentess} type="text" className="form-control" id="Mentess" placeholder="Mentess" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Jurusan" className="form-label">Jurusan</label>
              <div className="input-group has-validation">
                <input disabled value={data?.jurusan} type="text" className="form-control" id="Jurusan" placeholder="Jurusan" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Universitas" className="form-label">Universitas</label>
              <div className="input-group has-validation">
                <input disabled value={data?.universitas} type="text" className="form-control" id="Universitas" placeholder="Universitas" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Divisi" className="form-label">Divisi</label>
              <div className="input-group has-validation">
                <input disabled value={data?.divisi} type="text" className="form-control" id="Divisi" placeholder="Divisi" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Nilai Rata-Rata" className="form-label">Nilai Rata-Rata</label>
              <div className="input-group has-validation">
                <input disabled value={data?.average_nilai} type="text" className="form-control" id="Nilai Rata-Rata" placeholder="Nilai Rata-Rata" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Nilai Akhir" className="form-label">Nilai Akhir</label>
              <div className="input-group has-validation">
                <input disabled value={data?.total_nilai} type="text" className="form-control" id="Nilai Akhir" placeholder="Nilai Akhir" />
              </div>
            </div>

            <div className="col-12 table-responsive card p-3">

              <div className="d-flex justify-content-between mb-3">
                <h1 className='h5'>Bakrie Center Foundation</h1>
              </div>
              {!data?.by_bcf?.length < 1 &&
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th style={{ width: 10 }} scope="col">#</th>
                      <th style={{ width: 350 }} scope="col">KPI</th>
                      <th style={{ width: 10 }} scope="col">Nilai</th>
                      <th scope="col">Catatan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.by_bcf?.map((bcf, key) => (
                      <tr key={key} style={{ cursor: 'default' }}>
                        <th scope="row">{key + 1}</th>
                        <td>{bcf.klasifikasi}</td>
                        <td>{bcf.nilai}</td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }

            </div>

            <div className="col-12 table-responsive card p-3">
              <div className="d-flex justify-content-between mb-3">
                <h1 className="h5">Mentors</h1>
              </div>
              <table className='table table-hover'>
                {!data?.hardskills_by_mentors?.length < 1 ?
                  <>
                    <thead>
                      <tr>
                        <th style={{ width: 10 }} scope="col">#</th>
                        <th style={{ width: 350 }} scope="col">KPI Hardskill</th>
                        <th style={{ width: 10 }} scope="col">Nilai</th>
                        <th scope="col">Catatan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.hardskills_by_mentors?.map((hard, key) => (
                        <tr key={key} style={{ cursor: 'default' }}>
                          <th scope="row">{key + 1}</th>
                          <td>{hard.klasifikasi}</td>
                          <td>{hard.nilai}</td>
                          <td>{hard.catatan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                  :
                  <p>Mentor belum menilai!</p>
                }
                {!data?.softskills_by_mentors?.length < 1 &&
                  <>
                    <thead>
                      <tr>
                        <th style={{ width: 10 }} scope="col">#</th>
                        <th style={{ width: 350 }} scope="col">KPI Softskill</th>
                        <th style={{ width: 10 }} scope="col">Nilai</th>
                        <th scope="col">Catatan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.softskills_by_mentors?.map((soft, key) => (
                        <tr key={key} style={{ cursor: 'default' }}>
                          <th scope="row">{key + 1}</th>
                          <td>{soft.klasifikasi}</td>
                          <td>{soft.nilai}</td>
                          <td>{soft.catatan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                }
              </table>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default EvaluationFinal;
